import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
} from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  authInterceptor,
  errorInterceptor,
  unauthErrorInterceptor,
  xsrfInterceptor,
} from './helpers/interceptors/http.interceptor';
import { DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import { catchError, of, tap } from 'rxjs';
import { IdentityService } from './http/identity/identity.service';
import { StorageService } from './helpers/storage/storage.service';
import jwtDecode from 'jwt-decode';
import { ProfileService } from '@/api/profile/profile.service';
import { IDecodedAccessToken } from './http/identity/identity.models';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (storageService: StorageService, profileService: ProfileService) =>
        () => {
          profileService.currentProfile$.set(
            storageService.getImpersonatedBy(),
          );
        },
      deps: [StorageService, ProfileService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          storageService: StorageService,
          identityService: IdentityService,
          router: Router,
        ) =>
        () => {
          const urlParams = new URLSearchParams(window.location.search);
          const refreshToken = urlParams.get('refreshToken');
          if (!refreshToken) {
            return of(null);
          }

          const companyId = urlParams.get('companyId') ?? null;

          return identityService
            .loginUsingRefresh(refreshToken, companyId)
            .pipe(
              catchError(() => of(null)),
              tap((data) => {
                if (!data) {
                  router.navigate(['/auth/login']);
                  return;
                }
                storageService.setAccessToken(data.accessToken);
                storageService.setRefreshToken(data.refreshToken);
                storageService.setUserId(data.userId);
                storageService.setUserAndRole(
                  jwtDecode<IDecodedAccessToken>(data.accessToken),
                );
                storageService.setImpersonatedBy(null);

                const companyId = data.companies?.[0]?.id ?? null;
                if (companyId) {
                  storageService.setCompanyId(companyId);
                  router.navigate(['/app/playbooks']);
                } else {
                  router.navigate(['/admin/companies']);
                }
              }),
            );
        },

      deps: [StorageService, IdentityService, Router],
      multi: true,
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        width: '650px',
        hasBackdrop: true,
        disableClose: true,
      } as DialogConfig,
    },
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        unauthErrorInterceptor,
        errorInterceptor,
        xsrfInterceptor,
      ]),
    ),
  ],
};
