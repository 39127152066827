import { Route } from '@angular/router';
import { companyRoutes } from '../company/companyRoutes';
import { UserRole } from '@/models/user-role.model';
import { roleGuard } from 'app/guards/auth.guard';
import { templatesRoute } from './navigation/templateRoute';
import { peopleRoute } from '@/admin/navigation/peopleRoute';
import { companyResolver } from 'app/resolvers/company.resolver';
import { profileResolver } from 'app/resolvers/profile.resolver';
import { companyIdResolver } from 'app/resolvers/company-id.resolver';

export const adminRoute: Route[] = [
  {
    path: 'admin',
    canActivate: [roleGuard],
    loadComponent: () =>
      import('@/admin/admin.component').then((c) => c.AdminComponent),
    data: {
      allowedRoles: [
        UserRole.StandardVPlaybookAdmin,
        UserRole.SuperVPlaybookAdmin,
        UserRole.CompanyReader,
        UserRole.VPlaybookReader,
      ],
    },
    children: [
      {
        path: 'company/:companyId',
        loadComponent: () =>
          import('../company/company.component').then(
            (c) => c.CompanyComponent,
          ),
        resolve: {
          companyId: companyIdResolver,
          company: companyResolver,
        },
        children: [...companyRoutes],
      },
      peopleRoute,
      templatesRoute,
      {
        path: 'companies',
        loadComponent: () =>
          import('@/admin/companies/companies.component').then(
            (c) => c.CompaniesComponent,
          ),
      },
    ],
  },
];
